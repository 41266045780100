import '../css/Program.css'
import { programsData } from '../../data/programsData'
import Rightarrow from '../../assets/rightArrow.png'

import React from 'react'

const Program = () => {
    return (
        <div className='Program' id='Programs'>
            {/* header */}

            <div className="programheader">
                <span className='storkeb'>Explor Our</span>
                <span >Programs</span>
                <span className='storkeb'>To shape you</span>
            </div>
            <div className="program-categories">
                {programsData.map((program) => (
                    <div className="category">
                        {program.image}
                        <span>{program.heading}</span>
                        <span>{program.details} </span>
                        <div className="join-now"><span>Join Now</span>
                            <img src={Rightarrow} alt="" /></div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Program