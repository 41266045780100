import "../css/reason.css";
import img from "../../assets/image1.png";
import img2 from "../../assets/image2.png";
import img3 from "../../assets/image3.png";
import img4 from "../../assets/image4.png";
import nb from "../../assets/nb.png";
import nike from "../../assets/nike.png";
import adi from "../../assets/adidas.png";
import tik from "../../assets/tick.png";

import React from "react";

function reason() {
  return (
    <div className="reason" id="reason">
      <div className="left-l">
        <img src={img} alt="" />
        <img src={img2} alt="" />
        <img src={img3} alt="" />
        <img src={img4} alt="" />
      </div>
      <div className="right-r">
        <span> Reasons</span>
        <div>
          <span className="storkeb">Why </span>
          <span className="choose">Choose us?</span>
        </div>

        <div className="details-r">
          <div>
            <img src={tik} alt="" />
            <span>Lorem ipsum dolor sit amet consectetur </span>
          </div>
          <div>
            <img src={tik} alt="" />
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </span>
          </div>
          <div>
            <img src={tik} alt="" />
            <span>Lorem ipsum dolor </span>
          </div>
          <div>
            <img src={tik} alt="" />
            <span>Lorem ipsum </span>
          </div>
        </div>
        <span>Our Partners</span>
        <div className="partners">
          <img src={nike} alt="" />
          <img src={nb} alt="" />
          <img src={adi} alt="" />
        </div>
      </div>
    </div>
  );
}

export default reason;
