import React from "react";
import "../css/Header.css";
import Logo from "../../assets/logo.png";
import bars from "../../assets/bars.png";
import { useState } from "react";
const Header = () => {
  const [menuOpended, setMenuOpened] = useState(false);
  const mobile = window.innerWidth <= 768 ? true : false;
  return (
    <div className="header">
      <img src={Logo} alt="" className="logo" />
      {menuOpended === false && mobile === true ? (
        <div style={{
          borderRadius:'5px',
          padding:'0.5rem',
          backgroudColor:"var(--appColor)"
        }} onClick={()=>setMenuOpened(true)}>
          <img
            src={bars}
            alt=""
            style={{ width: "1.5rem", height: "1.5rem" }}
          />{" "}
        </div>
      ) : (
        <ul className="header-menu">
          <li onClick={()=>setMenuOpened(false  )}>Home</li>
          <li onClick={()=>setMenuOpened(false  )}>Blog</li>
          <li onClick={()=>setMenuOpened(false  )}>About us</li>
          <li onClick={()=>setMenuOpened(false  )}>Plans</li>
          <li onClick={()=>setMenuOpened(false  )}>Why us</li>
        </ul>
      )}
    </div>
  );
};

export default Header;
