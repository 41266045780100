import './App.css';
import Headpart from './components/Headpart.jsx'
import Program from './components/Program/Program'
import Reason from './components/reason/Reason'
import Plans from './components/Plans/Plans'
import Testimonials from './components/testimonials/Testimonials';
import Join from './components/Join/Join';
import Footer from './components/footer/Footer';
function App() {
  return (
    <div className="App">
       <Headpart/>
       <Program/>
       <Reason/>
       <Plans/>
       <Testimonials/>
       <Join/>
     <Footer/>  
    </div>
  );
}

export default App;
