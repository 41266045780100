import "../css/Join.css";
import React from "react";
import { useRef } from "react";
import emailjs from '@emailjs/browser'; 
const Join = () => {

const   form =useRef()

const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ilt6bn4', 'template_trctvf5', form.current, 'E5jfyEOisqrK0AWd-')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="join" id="join-us">
      <div className="join-l">
      <div>
        <hr />
        <span>READY TO</span>
        <span className="storkeb"> LEVEL UP</span>
        </div>
        <div>
        <span>YOUR BODY</span>
        <span className="storkeb"> WITH US? </span>
        </div>
      </div>
      <div className="join-r">
        <form ref={form} className="email" onSubmit={sendEmail}>
            <input type="email" name="email_id" placeholder="Enter Your email" />
            <button className="raise-button with-shadow">Join Now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
