import "../css/footer.css";

import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <span className="storkeb">Nothing to see here</span>
    </div>
  );
};

export default Footer;
