import "../components/css/main.css";
import React from "react";
import Header from "./Header/Header";
import main_image from "../assets/main-image.png";
import heart from "../assets/heart.png";
import main_back from "../assets/main-back.png";
import calories from "../assets/calories.png";
import { motion } from "framer-motion";

const main = () => {
  const transition = { type: "spring", duration: 3 };

  return (
    <div className="hero">
      <div className="blur blur-h"></div>
      <div className="left">
        <Header />
        <div className="thebest">
          <motion.div
            initial={{ left: "232px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>the best fitness in the town</span>
        </div>

        <div className="main-text">
          <div>
            <span className="storkeb">SHAPE </span>
            <span>YOURSELF</span>
          </div>
          <div>
            <span>IDEA BODY</span>{" "}
          </div>

          <div>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
              quia natus velit rem ab, sint quis recusandae eveniet id ducimus
              dignissimos magnam doloribus unde quidem nemo adipisci nihil
              quaerat totam.
            </span>
          </div>
        </div>
        {/* figures */}

        <div className="figures">
          <div>
            <span>+ 20</span>
            <span>Expert Coaches</span>
          </div>
          <div>
            <span>+ 222</span>
            <span>Current Members</span>
          </div>
          <div>
            <span>+ 50</span>
            <span>Fitness Programs</span>
          </div>
        </div>
        {/* .buttons */}
        <div className="main-buttons">
          <button className="btn">Get Started</button>
          <button data-text="You Made it" className="btn2">
            <span> Learn More</span>
          </button>
        </div>
      </div>
      {/* ---------------right side of the div--------- */}

      <div className="right">
        <button className="button">Join Now</button>

        <motion.div
          className="pulse"
          initial={{ right: "-1rem" }}
          whileInView={{ right: "4rem" }}
          transition={transition}
        >
          <img src={heart} alt="heart" className="heart" />
          <span>Heart Rate</span>
          <span> 11bpm</span>
        </motion.div>
      </div>

      {/* ---------main images ------------ */}

      <img src={main_image} alt="mainimg" className="imgmain" />
      <motion.img  src={main_back} alt="backimg" className="backimg"  initial={{ right: "11rem" }}
        whileInView={{ right: "20rem" }}
        transition={transition} />

      {/*-------------calories---------- */}

      <motion.div
        className="calories"
        initial={{ right: "37rem" }}
        whileInView={{ right: "28rem" }}
        transition={transition} 
      >
        <img src={calories} alt="" className="calories-img" />
        <div>
          <span>Calories Burn</span>
          <span>222kcal</span>
        </div>
      </motion.div>
    </div>
  );
};

export default main;
